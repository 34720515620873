.content-top{
//background: rgb(13, 15, 18);
   height: 100px;
//border-bottom: 2px solid rgb(237, 50, 90); 
//position: fixed;
   z-index: 1000;
   width: 100%;
   margin-top:0px;
  
}
.middle-top-bar{
    display: flex;
    background: rgb(13, 15, 18);
// background: #289494;
    height: 50px;
    margin-top: 0px;
// border-bottom: 2px solid rgb(237, 50, 90); 
// position: fixed;
   z-index: 1000;
   width: 100%; 
}


