
.container {
  display: flex;
  justify-content: space-between;
  height: auto;
  margin-top: 5px;
  background: #dcdcdcdc;
}

body {
 overflow-x: hidden;
  background-image: url("./images/bg.jpg");
  font-family:"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 17px;
}
.right-side-container span p{
  // background-image: url("./images/bg.jpg");
  background: #fff;
}
.social-icons-container a{
  border: 1px solid #289494;
  padding: 1px;
}
.social-icons-container h6 a{
color: steelblue;

}
.social-icons-container h6 a:hover{
   background-color: maroon;
   color: whitesmoke;
}
body a {
  text-decoration: none;
}
.centerblock {
  background-color: $white;
  border: 0px solid white;
  margin-left: 5px;
  margin-right: 5px;
   width: 80%;
  text-align: center;
}
.side-left {
  width: 30%;
  background: #dcdcdcdc;
  color: $black;
  margin-left: -24px;
  text-align: center;
  padding: 20px;
}
.side-left h3 {
  //font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  padding: 10px;
  font-size: 17px;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

.side-right {
  width: 30%;
  background: #dcdcdcdc;
  color: $black;
  margin-right: -25px;
  text-align:left;
  padding: 20px;
}
.side-right h3 {
  //  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  padding: 6px;
  font-size: 17px;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

.right-side-container p {
  padding: 6px;
  margin: 5px;
  text-align: left;
  font-size: 17px;
  color: $black;
}
.right-side-container p h3 {
  text-align: center;
}

.top-footer {
  background: $black;
  height: 100px;
  display: flex;
  margin-top: 5px;
  border-top: 3px solid rgb(237, 50, 90);
 
}
.middle-footer {
  background-color: #289494;
  //background-image: url("./images/footer-bg.png");
  background-image: url("./images/FOOTER_bg.jpg");
 
  display: flex;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
}
// .down-footer{
// background: $black;
// height: 100px;
//display: flex;

//}
//hr.footer-separate {
// border-top: 3px dashed whitesmoke;
//width: 100%;
//margin-top:300px;
//}
.social-icons-container {
  margin-top: 80px;
  margin-right: 5px;
}
.social-icons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 10px;
  font-size: 30px;
  color: $white;
  background: maroon;
}
.copyright-container h6 {
  background: rgb(13, 15, 18);
  text-align: center;
  margin-bottom: 0px;
  padding: 10px;
  color: whitesmoke;
}
.card-sliders {
  width: 100%;
  height: 400px;
  margin-right: 15px;
}
.carousel-inner .carousel-caption p {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  color: whitesmoke;
}

.row-footer-1 p {
  margin-left: 15px;
  margin-right: 15px;
  color: $white;
  text-align: justify;
  font-size: 16px;
  // font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

.row-footer-1 p h3 {
  margin-left: 15px;
  margin-right: 15px;
  color: $white;
  text-align: center;
  font-size: 20px;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.myrow-content p {
  text-align: justify;
}

.myrow-content .mycard {
  padding: 10px;
  margin-left: 1px !important;
  margin-right: 1px !important;
  margin-top: 1px !important;
}
.myrow-content .mycard h5 {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.myrow-content .mycard img {
  height: 200px;
}
.row-article .mycard {
  padding: 10px;
  margin-left: 1px !important;
  margin-right: 1px !important;
  margin-top: 1px !important;
}
.row-article h1,h3,h6,.commentaire-title{
font-family:Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif !important ;
}
.row-article .mycard3 p{
  padding: 10px;
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-top: 1px !important;
}
.myrow-content .mycard2 {
  background: #dcdcdcdc;
  padding: 10px;
  height: auto;
  margin-top: 5px;
  margin-left: 1px;
  margin-right: 1px;
}

.myrow-content .mycard2 img{
  background: #dcdcdcdc;
  padding: 10px;
  height: 150px;
  margin-top: 5px;
  margin-left: 1px;
  margin-right: 1px;
}

.image-container {
  height: 400px !important;
}

.left-side-container {
  padding: 10px;
  // background-image: url('./images/image5.jpg');
  color: $black;
}
.left-side-container {
  // background-image: url("./images/image5.jpg");
  background: #fff;
}
.left-side-container span {
  margin-top: 4px;
  list-style-type: none;
  padding: 1px;
  color: $black;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-weight: bold;
  text-decoration: none;
  font-size: 25px;
}


// .left-side-container li:hover {
//   border-bottom: 5px solid maroon;
//   margin-bottom: 10px;
//   color: whitesmoke;
// }

.image-container-pathner img {
  display: flex;
  height: 100px;
  width: 100px;
  justify-items: center;
}
.sr-only {
  font-size: 12px !important;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif !important;
  color: $black !important;
}
.logo {
  display: flex;
  align-items: center;
  margin-left: 80px;
}
.logo .login-text {
  //margin-left: 1000px !important;
  color: whitesmoke;
  margin-top: 3px;
}

.login-text a {
  color: whitesmoke;
  font-weight: bold;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-size: 17px;
 
}

.logo .item {
  flex: 1 1 200px;
}
.logo .item1 {
  flex-grow: 0;
}
.logo .item2 {
  flex-grow: 4;
  margin-right: 23px;
}
.logo .item3 {
  flex-grow: 0;
  text-align: center;
  margin-right: 60px;
}
.logo .item3 a:hover {
  border-top: 2px solid whitesmoke;
  color: whitesmoke;
}
.logo img {
  width: 80px;
  height: 80px;
  margin-top: 10px;
  margin-left: 10px;
}
.logo .logo-text {
  margin-left: 5px;
}
.logo .logo-text h3 {
  //font-family: 'Courier New', Courier, monospace;
  color: whitesmoke;
}

.fade-in {
  transition: opacity 1s ease;
  color: $black;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
  color: $black;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.fade-container {
  display: flex;
  justify-items: center;
  // text-align: center;
  height: auto;
}
.fade-container  .itemf{
  display: flex;
  justify-content: space-around;
  height: auto;
  flex-grow: 3;
  margin-top: 0px;
}
.fade-container  .itemf span i{
  border: 1px solid #289494;
  padding: 3px;
 
}
.fade-container  .itemf span .i-contact,.fade-container .itemf span .i-email{
  margin-left: 26px;
  border: 0px;
 
}

.middle-top-bar .menu-text-container {
  flex-grow: 7;
  padding: 5px;
  margin-left: 90px;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.middle-top-bar .menu-container {
  display: flex;
  flex-grow: 1;
  padding: 5px;
  margin-right: 50px;
}
.middle-top-bar .menu-container .item {
  list-style-type: none;
  margin: 0px;
  margin-top: -5px;
  padding: 10px;
}

.middle-top-bar .menu-container .item a,
.szh-menu-button {
  font-size: 14px;
  text-decoration: none;
  color: whitesmoke;
  text-transform: uppercase;
  font-weight: bold;
  //font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
 
}

.middle-top-bar .menu-container .item a:hover {
  border-top: 3px solid whitesmoke;
  border-width: 3px;
}
.menu-text-container {
  float: right;
  margin-top: 0px;
  justify-content: flex-start;
}
.menu-text-container h3 {
  color: whitesmoke;
  font-weight: bold;
}

.centerblock p {
  font-size: 17px;
  padding: 5px;
  margin: 1px;
  text-align: justify;
}
.centerblock-card-service p {
  font-size: 21px;
  margin-left: 70px;
  margin-right: 70px;
}
.active {
  border-bottom: 3px solid whitesmoke;
}
.card {
  border-radius: 0px;
}
.centerblock .mycard {
  background-color: #dcdcdcdc;
}
.login-container p {
  font-size: 22px;
}
.login-container h3 {
  font-size: 10px;
}
.login-container {
  text-align: left;
  padding: 10px;
  margin: 10px;
}
.contact-numbers-container {
  padding: 10px;
  background: #dcdcdcdc;
}
.centerblock p h3 {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  text-align: center;
}
.centerblock p h3 {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  text-align: center;
}
.login-container {
  // font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  text-align: center;
}
.login-container .title-contact-form{
font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
font-size: 28px;
}
.mission-list li {
  list-style-type: lower-alpha;
  font-size: 23px;
}
.red {
  color: red !important;
}
.toogle-menu-container {
  background: $black;
  padding: 10px;
  display: none;
}
.toogle-menu-container li {
  list-style-type: none;
  margin: 2px;
  padding: 5px;
  text-align: center;
}
.toogle-menu-container li a {
  color: whitesmoke;
  text-transform: uppercase;
  font-size: 18px;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.toogle-menu-container li a:hover {
  background: maroon;
  box-shadow: $shadow;
  border-bottom: 1px solid whitesmoke;
  padding: 5px;
}
.toogle-menu-container .span-menu-bars {
  font-size: 28px;
  color: whitesmoke;
  font-weight: bold;
}
.span-menu-bars-hide {
  font-size: 20px;
  color: whitesmoke;
  font-weight: bold;
  float: right;
  margin-right: 20px;
}
.myrow-content h3 {
  font-weight: bold;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

.szh-menu-button {
  background: none;
  margin-top: -8px;
}
.szh-menu-button:hover {
  background: none;
  border-top: 2px solid whitesmoke;
}
//react-horozontal-timeline
.text-center {
  text-align: center;
}

.root-div {
  margin-top: 200px;
  padding: 10px;
}

.colfoot1 p {
  margin-top: 80px;
}
.colfoot2 p {
  margin-top: 80px;
}
.colfoot3 p {
  margin-top: 80px;
}
.colfoot4 p {
  margin-top: 80px;
}
.colfoot4 li
.patner-content{
  width: 880px !important;
}
h5{
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

 .patner-content > div p img{
  height: 100px;
  width: 100px;
  
  }
  .patner-content{
    margin-top: 40px;
    margin-bottom: 50px;
    }
  
  .new-letter-form{
    padding: 5px;

  }
  .new-letter-form input{
    border-radius: 0px;
    padding: 3px;
    height: 40px;
    
  }
  .new-letter-form button{
    border-radius: 0px;
    padding: 3px;
    height: 40px;
    width: 110px;
    background:#289494;
    color: whitesmoke;
    
  }
  .form-news-letter{
    margin-top: 33px !important;
  }
  .wrapper-error-page{
    background-image: url("./images/ErrorImage.png");
    height: 100vh;
    width: 100%;
  }
  .sub-menu-toogle-container a{
    color: $black !important;
  }
  .sub-menu-toogle{
  font-family:"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  }
  .site-location-container{
    background:#289494;
    height: auto;
    width: 100%;
  }
  .site-location-container p{
    color:whitesmoke;
    padding: 2px;
    margin-left: 20px;
  }
  .article-cards p h1{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    text-align: center;
    background:whitesmoke;
    padding: 5px;
  }
  .epargne-container-text p{
  font-size: 20px;
  margin-left: 5px;
  margin-right: 5px;
  }

  .td-link a{
  font-weight: bold;
  font-size: 18px;
  color: whitesmoke;
  }
.row-article .commentaire-form{
margin: 10px auto;
padding: 10px;
background-color: #dcdcdcdc;


}
.row-article .commentaire-form .comment-texterea{
  height: 45px;
  border-radius: 0px;
  width: 100%;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

  }

  .row-article .commentaire-form button{
    border-radius: 0px;
    padding: 5px;
    height: 45px;
    width: 110px;
    background: #289494;
    color: whitesmoke;
   } 


 //Register css
 .note
 {
     text-align: center;
     height: 80px;
     background: -webkit-linear-gradient(left, #0072ff, #8811c5);
     color: #fff;
     font-weight: bold;
     line-height: 80px;
 }
 .form-content
 {
     padding: 5%;
     border: 1px solid #ced4da;
     margin-bottom: 2%;
 }
 .form-control{
     border-radius:1.5rem;
 }
 .btnSubmit
 {
     border:none;
     border-radius:1.5rem;
     padding: 1%;
     width: 20%;
     cursor: pointer;
     background: #0062cc;
     color: #fff;
 }
 //end register css

.copyright-container .footerIcons a {
  font-size:"22px";
  color:#fff;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  
  body{
    overflow-x: hidden !important;
  }
  .centerblock {

     width: 100%;
     margin: 0px;
  }
  .middle-top-bar .menu-container .item {
    display: none;
  }
  .logo {
    display: flex;
    align-items: center;
    margin-left: 0px;
  }
  .logo img {
    width: 55px;
    height: 55px;
  }

  .middle-top-bar .menu-text-container {
    display: none;
  }
  .side-left {
    display: none;
  }
  .side-right {
    display: none;
  }
  //i will rechek it
  .fade-container {
    margin-left: 50px;
  }
  .card-sliders {
    height: auto;
  }
  .myrow-content {
    margin-top: -120px !important;
  }
  .carousel-inner .carousel-caption {
    margin-bottom: 200px;
  }
  .center-pathner {
    margin-top: -160px !important;
  }
 .patner-content{
   width: 100% !important;
 }

  .myrow-content .mycard2,
  .mycard {
    height: auto;
  }

  .carousel-item {
    border: 0px;
  }
  .toogle-menu-container {
    display: block;
    margin-top: -80px;
    margin-bottom: 22px;
    height: 60px;
    background: #289494;
  }
  .spans-toogle-menu-container {
    display: flex;
    justify-content: space-between;
  }
  .toogle-menu-container {
    display: block;
    margin-top: -80px;
    margin-bottom: 22px;
    height: 60px;
    background: $black;
  }
  .show-hidden-menu {
    display: block;
    margin-top: -80px;
    margin-bottom: 5px;
    height: auto;
    background: $black;
  }
  .login-text h6 {
    display: inline-flex;
    font-size: 17px;
    margin-left: -110px;
    
  }
  .login-text h6 a{

    font-size: 22px;
   
    
  }

  .centerblock p {
    padding: 1px;
    margin: 1px;
    text-align: left;
  }
  .centerblock-card-service p {
    margin-left: 5px;
    margin-right: 5px;
  }
  .middle-top-bar .menu-container .item a,
  .szh-menu-button {
    font-size: 8px !important;
  }
  .fade-container {
  display: none;
  }
  .logo  img{
   margin-top: 3px !important;
  }
  .middle-footer {
    background-color: #289494;
    display: flex;
    height: auto;
    background-image: none;
  }
  .social-icons-container a{
    margin: 2px;
  }
  .sub-menu-toogle{
  font-weight: bold;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif !important;
  font-size: 17px;
  }
  
}
@media only screen and (max-width: 768px) {
  body{
    overflow-x: hidden !important;
  }
  .middle-footer {
    background-color: #289494;
    display: flex;
    height: auto;
  background-image: none;
  }
  .centerblock {
   margin: 0px;
    width: 100%;
 }
  .side-left,
  .side-right {
    display: none;
  }
  .logo {
    display: flex;
    align-items: center;
    margin-left: 0px;
  }
  .middle-top-bar .menu-text-container {
    display: none;
  }
  .menu-container {
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
  }
  .login-text {
    display: flex;
    font-size: 17px;
  }
  .logo img {
    width: 60px;
    height: 60px;
  }
  //i will rechek it
  .fade-container {
    margin-left: 50px;
  }
 
  .middle-top-bar .menu-container .item a,
  .szh-menu-button {
    font-size: 15px;
  }
  .patner-content{
    width: 100% !important;
    margin-top: 175px !important;
  }
  .colfoot1 p {
    margin-top: 0px;
  }
  .colfoot2 p {
    margin-top: 0px;
  }
  .colfoot3 p {
    margin-top: 0px;
  }
  .colfoot4 p {
    margin-top: 0px;
  }
  .fade-container {
    margin-left: none;
  }
  .fade-container{
    display: none;
  }
.social-icons-container{
  display: flex;
  margin-left: 300px;
}
.middle-top-bar .menu-container .item a,
.szh-menu-button {
  font-size: 9px;
}
.social-icons-container .social-icons{
  display: none;
}
.sub-menu-toogle{
  font-weight: bold;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif !important;
  font-size: 17px;
  }
}










